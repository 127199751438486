.banner {
  display: flex;
  justify-content: center;
  position: relative;
  isolation: isolate;
  margin-bottom: 35px;
  padding-bottom: 120px;
}
@media only screen and (max-width: 768px) {
  .banner {
    height: 210px;
  }
}
.banner .banner-img {
  object-position: bottom;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(0.5);
  z-index: -1;
}
.banner .full-row {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 60px 0;
}
.banner .full-row .banner-title {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 60px;
  line-height: 72px;
  color: #FFFFFF;
  max-width: 1000px;
}
@media only screen and (max-width: 1024px) {
  .banner .full-row .banner-title {
    font-size: 60px;
    line-height: 1;
  }
}
@media only screen and (max-width: 768px) {
  .banner .full-row .banner-title {
    font-size: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .banner .full-row .banner-title {
    font-size: 24px;
  }
}
.banner .full-row .banner-text {
  margin-top: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  font-size: 18px;
  color: #FFFFFF;
  max-width: 1000px;
}
.banner .full-row .buttons {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}
.banner .full-row .buttons a {
  color: #fff;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 20px;
  font-size: 14px;
  transition: 0.2s all ease;
  font-weight: bold;
}
.banner .full-row .buttons a:first-child {
  background-color: #0087D2;
}
.banner .full-row .buttons a:first-child:hover {
  color: #0087D2;
}
.banner .full-row .buttons a:last-child {
  background-color: #EF0000;
}
.banner .full-row .buttons a:last-child:hover {
  color: #EF0000;
}
.banner .full-row .buttons a:hover {
  background-color: #FFFFFF;
}
.banner .widget:has(.decoration-container) {
  position: absolute;
  bottom: -62px;
}
.banner .widget:has(.decoration-container) .bluepath {
  color: #0087D2;
}
.banner .widget:has(.decoration-container) .redpath {
  color: #EF0000;
}
.banner .widget:has(.decoration-container) .blackpath {
  color: transparent;
}
.banner .widget:has(.decoration-container) .whitepath {
  color: var(--background-color);
}
.banner .widget.block.block-static-block {
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
}
.banner .widget.block.block-static-block .bluepath {
  color: #0387D2;
}
.banner .widget.block.block-static-block .whitepath {
  color: #f5f5f5;
}
.banner .widget.block.block-static-block .redpath {
  color: #EF0000;
}
.banner .widget.block.block-static-block .decoration-container {
  overflow: hidden;
}

.category-list {
  margin-bottom: 127px;
}
@media only screen and (max-width: 768px) {
  .category-list {
    margin-bottom: 60px;
  }
}
.category-list .full-row .categoryListTitle {
  font-size: 40px;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categoryListTitle {
    font-size: 24px;
  }
}
.category-list .full-row .categoryListText {
  font-size: 18px;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categoryListText {
    font-size: 16px;
  }
}
.category-list .full-row .categories {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  gap: 20px;
  margin-top: 60px;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categories {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
.category-list .full-row .categories .category {
  --padding: 35px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 25px;
  aspect-ratio: 1;
  padding: var(--padding);
  line-height: 1;
  min-height: 0;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categories .category {
    --padding: 5px;
  }
}
.category-list .full-row .categories .category > a {
  flex-basis: 0;
  min-height: 0;
  flex-grow: 1;
}
.category-list .full-row .categories .category > a .categoryImage {
  display: block;
  min-height: 0;
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.category-list .full-row .categories .category .categoryTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  margin-top: auto;
  color: #4A4A4A;
  font-size: 21px;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categories .category .categoryTitle {
    font-size: 14px;
  }
}
.category-list .full-row .categories .category .categoryTitle a {
  color: inherit;
}
.category-list .full-row .categories .category .categoryTitle svg {
  color: #0087D2;
  margin-left: 1ch;
  transition: 0.3s;
}
.category-list .full-row .categories .category:hover svg {
  rotate: -90deg;
}
.category-list .full-row .categories .category .expander {
  display: grid;
  grid-template-rows: 0fr;
  position: absolute;
  width: calc(100% - 2 * var(--padding));
  z-index: 1;
  bottom: var(--padding);
  translate: 0 50%;
  max-height: 100%;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categories .category .expander {
    width: max-content;
  }
}
.category-list .full-row .categories .category .expander .subcategories {
  border-radius: 25px;
  background-color: #0087D2;
  overflow: auto;
}
.category-list .full-row .categories .category .expander .subcategories .main-category a {
  font-weight: bold;
}
.category-list .full-row .categories .category .expander .subcategories ol,
.category-list .full-row .categories .category .expander .subcategories li {
  margin: 0;
  padding: 0;
}
.category-list .full-row .categories .category .expander .subcategories ol {
  padding: 15px;
  display: grid;
  gap: 10px;
}
.category-list .full-row .categories .category .expander .subcategories ol li {
  margin: 0;
  background-color: #0087D2;
  position: relative;
  isolation: isolate;
  border-radius: 25px;
}
.category-list .full-row .categories .category .expander .subcategories ol li a {
  display: block;
  color: #FFFFFF;
  padding: 10px 20px;
  width: 100%;
  text-align: center;
  z-index: 1;
  font-size: 16px;
  -webkit-font-smoothing: auto;
}
@media only screen and (max-width: 768px) {
  .category-list .full-row .categories .category .expander .subcategories ol li a {
    font-size: 12px;
    padding: 0;
    text-align: left;
  }
}
.category-list .full-row .categories .category .expander .subcategories ol li:hover {
  scale: 1.05;
  background: white;
}
.category-list .full-row .categories .category .expander .subcategories ol li:hover a {
  color: #0087D2;
}
.category-list .full-row .categories .category .expander .subcategories ol li:hover::before {
  scale: 1.05;
  translate: 0 0;
}
.category-list .full-row .categories .category:hover .expander {
  grid-template-rows: 1fr;
}

.brands-slider {
  background: #FFFFFF;
  padding-top: 80px;
  padding-bottom: 100px;
}
@media only screen and (max-width: 768px) {
  .brands-slider {
    padding: 60px 0;
  }
}
.brands-slider .full-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.brands-slider .full-row > p:empty, .brands-slider .full-row p:not(:has(*)) {
  display: none;
}
.brands-slider .full-row .brands-title {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}
.brands-slider .full-row .splide {
  width: 100%;
  margin-top: 50px;
}
.brands-slider .full-row .splide .splide__track {
  max-width: 1000px;
  margin: 0 auto;
}
.brands-slider .full-row .splide .splide__track .splide__list .splide__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brands-slider .full-row .splide .splide__arrows .splide__arrow {
  position: absolute;
  top: 50%;
  translate: 0 -50%;
  background: none;
  border: none;
  padding: 10px;
}
.brands-slider .full-row .splide .splide__arrows .splide__arrow--prev {
  left: 0;
}
.brands-slider .full-row .splide .splide__arrows .splide__arrow--next {
  right: 0;
}
.brands-slider .full-row .splide .splide__arrows .splide__arrow svg {
  aspect-ratio: 1;
  width: 30px;
}
.brands-slider .full-row p:not(.brands-subtitle) {
  margin-top: 50px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
}
.brands-slider .full-row p:not(.brands-subtitle)::before {
  background: #FFFFFF;
  z-index: -1;
}
.brands-slider .full-row p:not(.brands-subtitle) .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
.brands-slider .full-row p:not(.brands-subtitle):hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
.brands-slider .full-row p:not(.brands-subtitle):hover * {
  color: #0087D2;
}
.brands-slider .full-row p:not(.brands-subtitle):hover::before {
  scale: 1.05;
  translate: 0 0;
}
.brands-slider .full-row .am-widget-brand-slider {
  width: 100%;
  isolation: isolate;
}
.brands-slider .full-row .am-widget-brand-slider .amslider.ambrands-slider {
  margin: 0;
  max-width: none !important;
}
.brands-slider .full-row .am-widget-brand-slider .amslider.ambrands-slider .ambrands-inner > * {
  background-color: transparent;
  border-radius: 0;
  color: #000000;
}
.brands-slider .full-row .am-widget-brand-slider .amslider.ambrands-slider .ambrands-inner .ambrands-swiper-brand {
  padding: 0;
}
.brands-slider .full-row .widget {
  margin-bottom: 0;
}
.brands-slider .full-row .widget a {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  display: block;
  padding: 9px 18px;
}
.brands-slider .full-row .widget a::before {
  background: #FFFFFF;
  z-index: -1;
}
.brands-slider .full-row .widget a .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
.brands-slider .full-row .widget a:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
.brands-slider .full-row .widget a:hover * {
  color: #0087D2;
}
.brands-slider .full-row .widget a:hover::before {
  scale: 1.05;
  translate: 0 0;
}
.brands-slider .full-row .widget .ambrands-slider {
  margin: 0;
}