@import "../base/variables";

.brands-slider {
    background: $white;
    padding-top: 80px;
    padding-bottom: 100px;
    @include respond($bp-768px){
        padding: 60px 0;
    }

    .full-row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        >p:empty,p:not(:has(*)){
            display: none;
        }

        .brands-title {
            font-size: 40px;
            font-weight: bold;
            margin: 0;
        }

        .brands-subtitle {}

        .splide {
            width: 100%;
            margin-top: 50px;

            .splide__track {
                max-width: 1000px;
                margin: 0 auto;

                .splide__list {
                    .splide__slide {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .splide__arrows {
                .splide__arrow {
                    position: absolute;
                    top: 50%;
                    translate: 0 -50%;
                    background: none;
                    border: none;
                    padding: 10px;

                    &--prev {
                        left: 0;
                    }

                    &--next {
                        right: 0;
                    }

                    svg {
                        aspect-ratio: 1;
                        width: 30px;
                    }
                }
            }
        }

        p:not(.brands-subtitle) {
            margin-top: 50px;
            margin-bottom: 0;
            @include button($blue, $white);
        }

        .am-widget-brand-slider {
            width: 100%;
            isolation: isolate;

            .amslider.ambrands-slider{
                margin: 0;
                max-width: none !important;
                
                .ambrands-inner {
                    >* {
                        background-color: transparent;
                        border-radius: 0;
                        color: $black;
                    }
    
                    .swiper-button-next {
                        // right: -13%;
    
                    }
    
                    .swiper-button-prev {
                        // left: -13%;
                    }
                    .ambrands-swiper-brand{
                        padding: 0;
                    }
                                    
                }
            }
        }

        .widget {
            margin-bottom: 0;

            a {
                @include button($blue, $white);
                display: block;
                padding: 9px 18px;
            }
            .ambrands-slider{
                margin: 0;
            }
        }
    }
}