@import "../base/variables";

.banner{
    display: flex;
    justify-content: center;
    position: relative;
    isolation: isolate;
    margin-bottom: 35px;
    padding-bottom: 120px;
    @include respond($bp-768px){
        height: 210px;
    }
    .banner-img {
        object-position: bottom;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: brightness(.5);
        z-index: -1;
    }
    .full-row{
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        padding: 60px 0;
        .banner-title {
            font-family: $raleway;
            font-weight: bold;
            font-size: 60px;
            line-height: 72px;
            color: $white;
            max-width: 1000px;
            @include respond($bp-1024px){
                font-size: 60px;
                line-height: 1;
            }
            @include respond($bp-768px){
                font-size: 30px;
            }
            @include respond($bp-480px){
                font-size: 24px;
            }
        }
        .banner-text{
            margin-top: 20px;
            font-family: $raleway;
            font-weight: bold;
            font-size: 18px;
            color: $white;
            max-width: 1000px;
        }
        .buttons{
            display: flex;
            gap: 20px;
            margin-top: 30px;
            a{
                color: #fff;
                padding: 10px 30px;
                display: inline-block;
                border-radius: 20px;
                font-size: 14px;
                transition: 0.2s all ease;
                font-weight: bold;

                &:first-child{
                    background-color: $blue;
                    &:hover{
                        color: $blue;
                    }
                }
                &:last-child{
                    background-color: $red;
                    &:hover{
                        color: $red;
                    }
                }
                &:hover{
                    background-color: $white;
                }
            }
        }
    }
    .widget:has(.decoration-container){
        position: absolute;
        bottom: -62px;
        .bluepath{
            color: $blue;
        }
        .redpath{
            color: $red;
        }
        .blackpath{
            color: transparent;
        }
        .whitepath{
            color: var(--background-color);
        }
    }

    .widget.block.block-static-block {
        position: absolute;
        bottom: 0;
        margin-bottom: 0;

        .bluepath {
            color: #0387D2;
        }

        .whitepath{
            color: #f5f5f5;
        }

        .redpath{
            color: #EF0000;
        }

        .decoration-container {
            overflow: hidden;
        }

    }
}