// MIXINS
@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}
@mixin center-absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin center-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1280px;
    padding-left: 20px;
    padding-right: 20px;
	width: 100%;
	box-sizing: border-box;
}
@mixin same-dim($dim) {
    height: $dim;
    width: $dim;
}
@mixin transition {
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}
@mixin btn-primary {
    font-family: $font-primary;
	background: transparent;
	border: 1px solid $color-primary;
    border-radius: 0; 
	margin-top: 20px;
	@include transition;
	letter-spacing: 0.1em;
	color: $color-primary;
	font-weight: 500;
	padding: 13px 40px;
    &:hover, &:active {
        background-color: $color-primary;
        border: 1px solid $color-primary;
        color: $color-white;
    }
}
@mixin btn-primary-white {
    font-family: $font-primary;
	background: transparent;
	border: 1px solid $color-white;
    border-radius: 0;
	margin-top: 20px;
	@include transition;
	letter-spacing: 0.1em;
	color: $color-white;
	font-weight: 500;
	padding: 13px 40px;
    &:hover{
        background-color: $color-white;
        color: $color-secondary;
    }
}
@mixin btn-primary-pseudo {
    font-family: $font-primary;
	background: $color-primary;
	border: 1px solid $color-primary;
    border-radius: 0;
    margin-top: 20px;
	@include transition;
	letter-spacing: 0.1em;
    color: $color-white;
	font-weight: 500;
	padding: 13px 40px;
    &:hover{
        background-color: $color-white;
        color: $color-primary;
    }
}
@mixin btn-slick-shadow {
    -webkit-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    -moz-box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
}
@mixin magento-hide{
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
@mixin magento-show{
    clip: auto;
    height: auto;
    width: auto;
    margin: 0;
    overflow: visible;
    position: static;
}

@mixin button($color, $hoverColor){
    position: relative;
    overflow: hidden;
    //transition: ease-out .3s;
    transition: ease-out .1s;
    isolation: isolate;
    background: $color;
    border: none;
    border-radius: 50px;
    box-shadow: 0 3px 6px rgba($black, .16);
    color: $hoverColor;
    &::before{
        //button transition
        //--slope: 30px;
        //content: "";
        background: $hoverColor;
        // clip-path: polygon(calc(100% - var(--slope)) 0, 100% 100%, calc(100% - var(--slope)) 100%, 0 100%, 0 0);
        // display: block;
        // height: 100%;
        // left: 0;
        // position: absolute;
        // top: 0;
        // transition: inherit;
        // translate: -100% 0;
        // width: calc(100% + var(--slope));
        z-index: -1;
    }
    .brands-link{
        color: $hoverColor;
        display: block;
        font-size: 14px;
        padding: 10px 20px;
    }
    &:hover{
        scale: 1.05;
        background-color: $hoverColor;
        *{
            color: $color;
        }
        &::before{
            scale: 1.05;
            translate: 0 0;
        }
    }
}
// Media Queries
// Declare in variables partial
@mixin respond($breakpoint) {
    @if $breakpoint ==$bp-1440px {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }

    @if $breakpoint ==$bp-1024px {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }

    @if $breakpoint ==$bp-768px {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }

    @if $breakpoint ==$bp-640px {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }

    @if $breakpoint ==$bp-480px {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }

    @if $breakpoint ==$bp-320px {
        @media only screen and (max-width: $breakpoint) {
            @content;
        }
    }
}