@import "../base/variables";

.category-list {
    margin-bottom: 127px;

    @include respond($bp-768px) {
        margin-bottom: 60px;
    }

    .full-row {
        .categoryListTitle {
            font-size: 40px;

            @include respond($bp-768px) {
                font-size: 24px;
            }
        }

        .categoryListText {
            font-size: 18px;

            @include respond($bp-768px) {
                font-size: 16px;
            }
        }

        .categories {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));

            @include respond($bp-768px) {
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            gap: 20px;
            margin-top: 60px;

            .category {
                --padding: 35px;
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: center;
                background-color: $white;
                box-shadow: 0 3px 6px rgba($black, .16);
                border-radius: 25px;
                aspect-ratio: 1;
                padding: var(--padding);
                line-height: 1;
                min-height: 0;

                @include respond($bp-768px) {
                    --padding: 5px;
                }

                >a {
                    flex-basis: 0;
                    min-height: 0;
                    flex-grow: 1;

                    .categoryImage {
                        display: block;
                        min-height: 0;
                        object-fit: contain;
                        height: 100%;
                        width: 100%;
                    }
                }

                .categoryTitle {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin: 0;
                    margin-top: auto;
                    color: $greyDarker;
                    font-size: 21px;

                    @include respond($bp-768px) {
                        font-size: 14px;
                    }

                    a {
                        color: inherit;
                    }

                    svg {
                        color: $blue;
                        margin-left: 1ch;
                        transition: .3s;
                    }
                }

                &:hover {
                    svg {
                        rotate: -90deg;
                    }
                }

                .expander {
                    display: grid;
                    grid-template-rows: 0fr;
                    // transition: ease-out .3s;
                    position: absolute;
                    width: calc(100% - 2 * var(--padding));
                    z-index: 1;
                    bottom: var(--padding);
                    translate: 0 50%;
                    max-height: 100%;

                    @include respond($bp-768px) {
                        width: max-content;
                    }

                    .subcategories {
                        border-radius: 25px;
                        background-color: $blue;
                        overflow: auto;

                        .main-category{
                            a{
                                font-weight: bold;
                            }
                        }

                        ol,
                        li {
                            margin: 0;
                            padding: 0;
                        }

                        ol {
                            padding: 15px;
                            display: grid;
                            gap: 10px;

                            li {
                                margin: 0;
                                background-color: $blue;
                                position: relative;
                                isolation: isolate;
                                border-radius: 25px;

                                a {
                                    display: block;
                                    color: $white;
                                    padding: 10px 20px;
                                    width: 100%;
                                    text-align: center;
                                    z-index: 1;
                                    font-size: 16px;
                                    -webkit-font-smoothing: auto;

                                    @include respond($bp-768px) {
                                        font-size: 12px;
                                        padding: 0;
                                        text-align: left;
                                    }
                                }

                                &:hover {
                                    scale: 1.05;
                                    background: white;

                                    a {
                                        color: $blue;
                                    }

                                    &::before {
                                        scale: 1.05;
                                        translate: 0 0;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    .expander {
                        grid-template-rows: 1fr;
                    }
                }
            }
        }
    }
}