$black: #000000;
$red:   #EF0000;
$blue:  #0087D2;
$white: #FFFFFF;
$greyLightest:  #F5F5F5;
$greyLighter:   #E2E1E1;
$greyLight:     #D6D6D6;
$greyDark:      #989898;
$greyDarker:    #4A4A4A;
$greyDarkest:   #333333;


// COLOR VARIABLES
$color-primary: $blue;
$color-secondary: $white;

$color-white: $white;
$color-black: $black;
$color-grey: #F6F6F7;
$color-dark-grey: #F7F7F7;
$color-buro210: #ED0579;


// FONT VARIABLES
$raleway: 'Raleway', sans-serif;
$font-primary: $raleway;
$font-primary-bold: normal normal bold 24px/30px 'Raleway', sans-serif;
$font-secondary: Arial, sans-serif;
$fontawesome: var(--fa-font-regular);
$fontawesomeLight: var(--fa-font-light);

$font-primary-size: 16px;

// MEDIA QUERIES
// PX to EM Convert: https://www.w3schools.com/tags/ref_pxtoemconversion.asp
// Magento Breakpoints: https://devdocs.magento.com/guides/v2.4/frontend-dev-guide/responsive-web-design/rwd_css.html
$bp-1440px: 1440px;
$bp-1024px: 1024px;
$bp-768px: 768px;
$bp-640px: 640px;
$bp-480px: 480px;
$bp-320px: 320px;